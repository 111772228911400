import React, { createContext, useContext, useEffect, useState } from 'react'
import './leftBarPannel.scss';
import logo from '../../../Assets/icons/Logo.svg'
import useWindowDimensions from '../../../CustomHooks/useWindowDimensions';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Auth from '../../../Classes/Auth';
import { setActiveTab } from '../../../store/Actions/PersonalDetails';
import { useDispatch } from 'react-redux';
import { adminID } from '../../../config';
import { showSuccessToast } from '../../../store/Actions/SuccessMessagePopup';

const LeftBarPannel = ({ toggelBtn, setToggelBtn, burgerBtn, leftSideRef, setBurgerBtn }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const employee_id = Auth?.user()?.employee_id // this is for added by user
    const data = [
        {
            tab_name: 'Dashboard',
            icon: 'dashboard',
            navigate: '/summary',
            active: '/summary'

        },
        {
            tab_name: 'Company documents',
            icon: 'documents',
            navigate: '/company-documents',
            active: '/company-documents',

        },
        {
            tab_name: 'User',
            icon: 'employees',
            navigate: '/user',
            active: '/user'

        },
        {
            tab_name: 'Clients',
            icon: 'employees',
            navigate: '/clients-management',
            active: '/clients-management'
        },
        {
            tab_name: 'Employees',
            icon: 'employees',
            navigate: '/employees-management',
            active: '/employees-management'
        },
        {
            tab_name: 'My Profile',
            icon: 'employees',
            // navigate: `/employees-management/id=${Auth?.boolUserType() !== 5 ? employee_id : Auth?.user()?.user_id}`,
            navigate: `/employees-management/id=${employee_id}`,
            active: '/employees-management'
        },

        {
            tab_name: 'View Documents',
            icon: 'view-documents',
            navigate: '/documents/all',
            active: '/documents'

        },
        {
            tab_name: 'Folders',
            icon: 'folder',
            navigate: '/folders',
            active: '/folders'

        },
        {
            tab_name: 'Access',
            icon: 'access-management',
            navigate: '/access-management',
            active: '/access-management',

        },
        {
            tab_name: 'Notifications',
            icon: 'notifications',
            navigate: '/notifications',
            active: '/notifications',

        },
        {
            tab_name: 'Register Users',
            icon: 'employees',
            navigate: '/snapit-admin',
            active: '/snapit-admin',
            condition: Auth.userType() === "Individual" && adminID === Auth?.user().user_id

        },

    ]

    // this function make for side toggling
    const toggleHandle = () => {
        setToggelBtn(e => !e)
    }

    // get window height & width
    const { width } = useWindowDimensions();

    // change active tab
    function ActiveTabHandler(val) {
        setBurgerBtn(e => !e)
        if (Auth?.boolUserType() == 3 && val == "My Profile") {
            dispatch(setActiveTab("Personal"))
        }
    }

    const logoutHandle = () => {
        Auth.logout()
        // navigate('/login')
        window.location.href = "/login"
        dispatch(showSuccessToast("Logout successfully!"))
    }

    return (
        <div className={`leftbarpannel-main-wrapper relative ${burgerBtn ? 'active' : ''}`}>
            <div ref={leftSideRef} className={`leftbarpannel-wrapper ${toggelBtn ? 'toggel-active' : ''} ${burgerBtn ? 'active' : ''}
            ${Auth.userType() === "Business Owner" ? "business-owner" :
                    Auth.userType() === "Typing Center" || Auth.userType() === "Typing Center User" ? "typing-center"
                        :
                        Auth.userType() === "Individual" ? "individual" : Auth.userType() === "employee_user" ? 'employee_user' : ""
                }
            `}>
                {/* {
                    width >= 993 && <button className={`transparent toggle-btn ${toggelBtn ? 'rotate' : ''}`} onClick={() => toggleHandle()}>
                        <i className="toggleIcon_open"></i>
                    </button>
                } */}
                <div className={`logo-wrapper`}>
                    <p className="name-heading secondary text-xxs w-500 text-left">Welcome,
                        <span className="name-heading primary text-xs w-500 text-left"> Al Aziz Business Services LLP</span>
                    </p>
                </div>
                <div className="list-item-wrapper">
                    {/* <ul className="list-wrap">
                        {data?.map((val, key) => (
                            shouldRenderTab(Auth.userType(), val.tab_name, val?.condition) && (
                                <Link
                                    key={key}
                                    className={`list-item pointer ${pathname?.includes(val.active) ? 'active' : ''}`}
                                    onClick={() => ActiveTabHandler(val.tab_name)}
                                    to={val.navigate}
                                >
                                    <i className={`${val.icon} icons`}></i>
                                    <p className="item-name w-700 text-xxs secondary">{val.tab_name}</p>
                                </Link>
                            )
                        ))}
                    </ul> */}
                    <ul className="list-wrap">
                        {
                            data?.map((val, key) => {
                                return (
                                    Auth.userType() === "Typing Center" && (val.tab_name !== "My Profile" && val.tab_name !== "Register Users")
                                        || Auth.userType() === "Typing Center User" && (val.tab_name !== "User" && val.tab_name !== "My Profile" && val.tab_name !== "Register Users" && val.tab_name !== "Company documents")
                                        || Auth.userType() === "Business Owner" && (val.tab_name !== "Clients" && val.tab_name !== "User" && val.tab_name !== "My Profile" && val.tab_name !== "Register Users")
                                        ||

                                        (Auth.userType() === "Individual" && adminID === Auth?.user().user_id)
                                        && (val.tab_name !== "Employees" && val.tab_name !== "Clients" && val.tab_name !== "User" && val.tab_name !== "My Profile" && val.tab_name !== "Dashboard" && val.tab_name !== "Folders" && val.tab_name !== "View Documents" && val.tab_name != "Access" && val.tab_name !== "Notifications" && val.tab_name !== "Company documents")
                                        ||
                                        (Auth.userType() === "Individual" && adminID !== Auth?.user().user_id)
                                        && (val.tab_name !== "Employees" && val.tab_name !== "Clients" && val.tab_name !== "User" && val.tab_name !== "Register Users" && val.tab_name !== "Company documents")


                                        || Auth.userType() === "employee_user" && (val.tab_name == "My Profile" && val.tab_name !== "Register Users" && val.tab_name !== "Company documents")
                                        ?
                                        <Link key={key}
                                            className={`list-item pointer ${pathname?.includes(val.active) ? 'active' : ''} `}
                                            onClick={() => ActiveTabHandler(val.tab_name)}
                                            to={val.navigate}
                                        >
                                            <i className={`${val.icon} icons`}></i>

                                            <p className={`item-name w-700 text-xxs secondary `}>{val.tab_name}</p>
                                        </Link>
                                        : ""
                                )
                            })
                        }
                    </ul>
                </div>
                <div className={`logout-wrap ${toggelBtn ? 'toggel-logout-active' : ''}`} >
                    <button className={`logout-btn w-400 text-xs align-center white `} onClick={logoutHandle} >
                        <i className='logout-icon'></i>
                    </button>
                    <Link className={`logout-btn w-400 text-xs align-center white `} to={"/recycle"}>
                        <i className='logout-icon trash-icon'></i>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LeftBarPannel