import React, { useEffect, useRef, useState } from 'react'
// import "./css/bootstrap.min.css"
import "./css/custom.scss"
import './home.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import mobileman from './images/mobileman.jpg'
import iconUpload from './images/icon_upload.png';
import iconAI from './images/icon_ai.png';
import iconReminder from './images/icon_reminder.png';
import iconSvg from './images/icon.svg';
import tech2 from './images/tech2.png';
import SQLimg from './images/SQL.png';
import phython from './images/phython.png';
import github from './images/github.png';
import react from './images/react.png';
import mobile1 from './images/mobile1.png';
import icon1 from './images/icon1.svg';
import icon2 from './images/icon2.svg';
import icon4 from './images/icon4.svg';
import icon5 from './images/icon5.svg';
import dataImg from './images/data.png';
import interfaceImg from './images/interface.png';
import reminder from './images/reminder.png';
import qsnMark from './images/a.png';
import blog1 from './images/blog1.png';
import blog2 from './images/blog2.png';
import blog3 from './images/blog3.png';
import blog4 from './images/blog4.jpg';
import nounCross from './images/noun-cross.png';
// Import Swiper styles
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/grid";
// import required modules
import { Autoplay, Parallax, Pagination, Navigation, Keyboard, Mousewheel, EffectCoverflow, Grid } from 'swiper/modules';
import { Link, useLocation } from 'react-router-dom';
import useDetectOutsideClick from '../../CustomHooks/useDetectOutsideClick';
import PricingItem from './PricingItem/PricingItem';
import GetInvite from './components/GetInvite/GetInvite';

const LandingPage = () => {
    const inviteRef = useRef()
    const [pop1, setPop1] = useState(false)
    const [pop2, setPop2] = useState(false)
    const [pop3, setPop3] = useState(false)
    const [pop4, setPop4] = useState(false)
    const [invite, setInvite] = useDetectOutsideClick(inviteRef, false)

    useEffect(() => {
        document.getElementById('playButton').addEventListener('click', function () {
            // Get the audio element
            var audio = document.getElementById('myAudio');

            // Play the audio
            audio.play();
        });

        document.getElementById('playButtons').addEventListener('click', function () {
            // Get the audio element
            var audio = document.getElementById('myAudios');

            // Play the audio
            audio.play();
        });

    })

    useEffect(() => {
        AOS.init();
    }, [])

    const path = useLocation()
    useEffect(() => {
        if (path.pathname === "/") {
            import('./css/bootstrap.min.css')
        }
    }, []);


    function openNav() {
        document.getElementById("myNav").style.width = "280px";
    }

    function closeNav() {
        document.getElementById("myNav").style.width = "0%";
    }

    function openNavs() {
        document.getElementById("myNavs").style.width = "280px";
    }

    function closeNavs() {
        document.getElementById("myNavs").style.width = "0%";
    }

    function openNavs2() {
        document.getElementById("myNavs2").style.width = "280px";
    }

    function closeNavs2() {
        document.getElementById("myNavs2").style.width = "0%";
    }

    function openNavs3() {
        document.getElementById("myNavs3").style.width = "280px";
    }

    function closeNavs3() {
        document.getElementById("myNavs3").style.width = "0%";
    }

    const swiperConfig = {
        className: "swiper-wrapper",
        spaceBetween: 0,
        centeredSlides: true,
        loop: true,
        speed: 1200,
        mousewheel: {
            enabled: true
        },
        keyboard: {
            enabled: true
        },
        coverflowEffect: {
            rotate: 50,
            slideShadows: true
        },
        // pagination: {
        //     clickable: true,
        // },
        observer: true,
        observeParents: true,
        parallax: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        modules: [Keyboard, Mousewheel, Pagination, Navigation, EffectCoverflow, Parallax, Grid],
    };

    const blogSwipperSettings = {
        className: "blog-swiper-wrapper",
        spaceBetween: 0,
        // centeredSlides: true,
        loop: false,
        speed: 1200,
        slidesPerView: 4,
        navigation: false,
        // navigation: {
        //     nextEl: '.swiper-button-next',
        //     prevEl: '.swiper-button-prev'
        // },
        // pagination: {
        //     clickable: true,
        // },
        // observer: true,
        // observeParents: true,
        // parallax: true, 
        // modules: [Navigation],
    }

    return (
        <div className='landing-page-main-wrapper'>
            {/* <link rel="stylesheet" type="text/css" href="./css/bootstrap.min.css" /> */}
            <div className="swiper-container">
                {/* Additional required wrapper */}
                <Swiper {...swiperConfig}
                // className="swiper-wrapper"
                // spaceBetween={30}
                // centeredSlides={true}
                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: false,
                // }}
                // pagination={{
                //     clickable: true,
                // }}
                // navigation={true}
                // modules={[Autoplay, Pagination, Navigation]}

                >
                    {/* Slides */}
                    <SwiperSlide className="swiper-slide">
                        <div id="demohomesss" className="overly1">
                            <header id="header">
                                <div className="container">
                                    <div className="header-dlf">
                                        <div className="header-logo">
                                            <Link to="/">
                                                <img src={iconSvg} alt="" />
                                                <svg
                                                    height={500}
                                                    width={1000}
                                                    stroke="#fff"
                                                    strokeWidth="0.8"
                                                    className="text-line"
                                                >
                                                    <text x={50} y={90} fill="none" className="snapit">
                                                        snap
                                                    </text>
                                                    <text x={200} y={90} fill="none" className="it">
                                                        it
                                                    </text>
                                                </svg>
                                            </Link>
                                        </div>
                                        <div className="header-menu">
                                            <ul className="list-unstyled m-0 p-0">
                                                <li>
                                                    <a href="#Works" className="Works">
                                                        How it Works
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Features" className="Features">
                                                        Feature
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Pricing" className="Pricing">
                                                        Pricing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#FAQ" className="FAQS">
                                                        FAQ
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Blog" className="menu  Blog">
                                                        Blog
                                                    </a>
                                                </li>
                                                {/* <li> */}
                                                {/* <Link to="/login" className="menu Blog">
                                                        Login
                                                    </Link> */}
                                                <span className="register-home-btn ml-5">
                                                    <a
                                                        type="button"
                                                        className="btn btn-primary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                        href="/login"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Get started
                                                    </a>
                                                </span>
                                                {/* </li>8 */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            {/*-close header--*/}
                            <div className="container">
                                <div className="evie-slide">
                                    <div className="row m-0">
                                        <div className="col-md-6 leftimg">
                                            <div className="title">
                                                <h1>
                                                    <span>Simplify</span> <span>License Renewals.</span>
                                                    <span>Timely Renewal.</span> <span>Reminders.</span>
                                                    <p>
                                                        <span>Transform how you manage your </span>{" "}
                                                        <span>documents with SnapIT.</span>
                                                    </p>
                                                    <span>
                                                        <a
                                                            href="/login"
                                                            className="btn"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal"
                                                        // onClick={() => setInvite(e => !e)}
                                                        >
                                                            Early Access
                                                        </a>
                                                    </span>
                                                </h1>
                                            </div>
                                        </div>
                                        <div className="col-md-6 side-img">
                                            <div
                                                id="playButton"
                                                className="lottie-icon fadeInUp"
                                                style={{ animationDelay: "1s" }}
                                            >
                                                {/* <Player
                                                    src="https://lottie.host/2335aa52-697b-4cc4-9240-71a16e69d53b/n2g8L5n0C0.json"
                                                    background="transparent"
                                                    speed={1}
                                                    style={{ width: 200, height: 200 }}
                                                    loop
                                                    controls
                                                    autoPlay
                                                    direction={1}
                                                    mode="normal"
                                                > 
                                                </Player> */}

                                                <Player
                                                    autoplay
                                                    loop
                                                    src="https://lottie.host/2335aa52-697b-4cc4-9240-71a16e69d53b/n2g8L5n0C0.json"
                                                    style={{ height: '200px', width: '200px' }}
                                                >
                                                    {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                                                </Player>

                                            </div>
                                            <audio id="myAudio">
                                                <source
                                                    src="https://myblulex.com/demo2023/test/audio/iphone-message.mp3"
                                                    type="audio/mp3"
                                                />
                                            </audio>
                                            {/*  <div className="popup-box fadeIndown" style="animation-delay:3s;">
              <h4>Renewal visa</h4>
            </div>
            <div className="popup-box fadeIndown" style="animation-delay:5s;">
              <h4>Renewal visa</h4>
            </div>
            <div className="popup-box fadeIndown" style="animation-delay:7s;">
              <h4>Renewal visa</h4>
            </div> */}
                                            <div
                                                className="popup-box  fadeIndownLast"
                                                style={{ animationDelay: "3s" }}
                                            >
                                                <h4>4 Renewal Reminders</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*-close evie-slide-*/}
                                <div className="copyright">
                                    <p>
                                        For First 100 Subscribers <small>|</small>Unlimited Documents,
                                        Folders, and Users.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={() => setInvite(e => !e)}
                                    >
                                        {" "}
                                        Contact us
                                    </button>
                                </div>
                                <div className="rot"></div>
                            </div>
                        </div>
                        {/*-demohome-*/}
                    </SwiperSlide>
                    {/*-slider 1--*/}
                    <SwiperSlide className="swiper-slide">
                        <div id="Works" className="demohome overly2">
                            <header id="header">
                                <div className="container">
                                    <div className="header-dlf">
                                        <div className="header-logo">
                                            <Link to="/">
                                                <img src={iconSvg} alt="" />
                                                <svg
                                                    height={500}
                                                    width={1000}
                                                    stroke="#fff"
                                                    strokeWidth="0.8"
                                                    className="text-line"
                                                >
                                                    <text x={50} y={90} fill="none" className="snapit">
                                                        snap
                                                    </text>
                                                    <text x={200} y={90} fill="none" className="it">
                                                        it
                                                    </text>
                                                </svg>
                                            </Link>
                                        </div>
                                        <div className="header-menu">
                                            <ul className="list-unstyled m-0 p-0">
                                                <li>
                                                    <a href="#Works" className="Works">
                                                        How it Works
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Features" className="Features">
                                                        Feature
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Pricing" className="Pricing">
                                                        Pricing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#FAQ" className="FAQS">
                                                        FAQ
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Blog" className="menu  Blog">
                                                        Blog
                                                    </a>
                                                </li>
                                                <span className="register-home-btn ml-5">
                                                    <a
                                                        type="button"
                                                        className="btn btn-primary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                        href="/login"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Get started
                                                    </a>
                                                </span>
                                                {/* <li>
                                                    <Link to="/login" className="menu Blog">
                                                        Login
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            {/*-close header--*/}
                            <div className="container">
                                <div className="evie-slide">
                                    <h2 className="hd">Save your Documents and Forget it</h2>
                                    <div
                                        className="row"
                                        style={{
                                            alignItems: "center",
                                            position: "relative",
                                            zIndex: 99
                                        }}
                                    >
                                        <div
                                            className="col-md-4 box fadeInUp"
                                            style={{ animationDelay: "2s" }}
                                        >
                                            <div className="renewal-img">
                                                <img src={iconUpload} alt="" />
                                            </div>
                                            <div className="textline">
                                                <h4>
                                                    <span>Easy</span> Documents <br /> Upload
                                                </h4>
                                                <p>
                                                    Scan or Upload Documents through Any <br /> Device
                                                    (Desktop, Tablets or Mobile)
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-4 box fadeInUp"
                                            style={{ animationDelay: "3s" }}
                                        >
                                            <div className="renewal-img">
                                                <img src={iconAI} alt="" />
                                            </div>
                                            <div className="textline">
                                                <h4>
                                                    <span>Organize</span> with <br /> Artificial Intelligence{" "}
                                                </h4>
                                                <p>
                                                    SnapIT securely organizes all your
                                                    <br /> documents in one place. <br />
                                                    Anytime, Anywhere, Accessible
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-4 box fadeInUp"
                                            style={{ animationDelay: "4s" }}
                                        >
                                            <div className="renewal-img">
                                                <img src={iconReminder} alt="" />
                                            </div>
                                            <div className="textline">
                                                <h4>
                                                    <span>Timely</span> <br /> Renewal Reminder
                                                </h4>
                                                <p>
                                                    {" "}
                                                    3 level Reminders on every renewal. <br />
                                                    Let's you SAVE on penalties
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*-close evie-slide-*/}
                                <div className="copyright">
                                    <p>
                                        For First 100 Subscribers <small>|</small>Unlimited Documents,
                                        Folders, and Users.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={() => setInvite(e => !e)}
                                    >
                                        {" "}
                                        Contact us
                                    </button>
                                </div>
                                <div className="leftside">
                                    <h3 className="fadeInUp" style={{ animationDelay: "2s" }}>
                                        <a href="#">How it works</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        {/*-demohome-*/}
                    </SwiperSlide>
                    {/*-slider 2--*/}
                    <SwiperSlide className="swiper-slide">
                        <div id="demohome" className="overly2">
                            <header id="header">
                                <div className="container">
                                    <div className="header-dlf">
                                        <div className="header-logo">
                                            <Link to="/">
                                                <img src={iconSvg} alt="" />
                                                <svg
                                                    height={500}
                                                    width={1000}
                                                    stroke="#fff"
                                                    strokeWidth="0.8"
                                                    className="text-line"
                                                >
                                                    <text x={50} y={90} fill="none" className="snapit">
                                                        snap
                                                    </text>
                                                    <text x={200} y={90} fill="none" className="it">
                                                        it
                                                    </text>
                                                </svg>
                                            </Link>
                                        </div>
                                        <div className="header-menu">
                                            <ul className="list-unstyled m-0 p-0">
                                                <li>
                                                    <a href="#Works" className="Works">
                                                        How it Works
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Features" className="Features">
                                                        Feature
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Pricing" className="Pricing">
                                                        Pricing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#FAQ" className="FAQS">
                                                        FAQ
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Blog" className="menu  Blog">
                                                        Blog
                                                    </a>
                                                </li>
                                                <span className="register-home-btn ml-5">
                                                    <a
                                                        type="button"
                                                        className="btn btn-primary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                        href="/login"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Get started
                                                    </a>
                                                </span>
                                                {/* <li>
                                                    <Link to="/login" className="menu Blog">
                                                        Login
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            {/*-close header--*/}
                            <div className="container">
                                <div className="evie-slide">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div
                                                className="slider3-cnt"
                                                style={{ position: "relative", zIndex: 99 }}
                                            >
                                                <h2 className="hd">SnapIT is built with</h2>
                                                <ul className="p-0 m-0 list-unstyled">
                                                    <li
                                                        className="fadesInUp"
                                                        style={{ animationDelay: "3s" }}
                                                    >
                                                        <a href="#">
                                                            <img src={SQLimg} alt="" />
                                                        </a>{" "}
                                                    </li>
                                                    <li
                                                        className="fadesInUp"
                                                        style={{ animationDelay: "4s" }}
                                                    >
                                                        <a href="#">
                                                            <img src={github} alt="" />
                                                        </a>{" "}
                                                    </li>
                                                    <li
                                                        className="fadesInUp"
                                                        style={{ animationDelay: "5s" }}
                                                    >
                                                        <a href="#">
                                                            <img src={phython} alt="" />
                                                        </a>
                                                    </li>
                                                    <li
                                                        className="fadesInUp"
                                                        style={{ animationDelay: "6s" }}
                                                    >
                                                        <a href="#">
                                                            <img src={react} alt="" />
                                                        </a>{" "}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-6 tech-img">
                                            <div className="tech-imgs">
                                                <img src={tech2} alt="" />
                                            </div>
                                        </div>
                                        <div className="viewport"></div>
                                    </div>
                                </div>
                                {/*-close evie-slide-*/}
                                <div className="copyright">
                                    <p>
                                        For First 100 Subscribers <small>|</small>Unlimited Documents,
                                        Folders, and Users.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={() => setInvite(e => !e)}
                                    >
                                        {" "}
                                        Contact us
                                    </button>
                                </div>
                                <div className="leftside">
                                    <h3 className="fadeInUp" style={{ animationDelay: "2s" }}>
                                        <a href="#">Technologies</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        {/*-demohome-*/}
                    </SwiperSlide>{" "}
                    {/*-slider 3--*/}
                    <SwiperSlide className="swiper-slide">
                        <div id="Features" className="overly2 demohome">
                            <header id="header">
                                <div className="container">
                                    <div className="header-dlf">
                                        <div className="header-logo">
                                            <Link to="/">
                                                <img src={iconSvg} alt="" />
                                                <svg
                                                    height={500}
                                                    width={1000}
                                                    stroke="#fff"
                                                    strokeWidth="0.8"
                                                    className="text-line"
                                                >
                                                    <text x={50} y={90} fill="none" className="snapit">
                                                        snap
                                                    </text>
                                                    <text x={200} y={90} fill="none" className="it">
                                                        it
                                                    </text>
                                                </svg>
                                            </Link>
                                        </div>
                                        <div className="header-menu">
                                            <ul className="list-unstyled m-0 p-0">
                                                <li>
                                                    <a href="#Works" className="Works">
                                                        How it Works
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Features" className="Features">
                                                        Feature
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Pricing" className="Pricing">
                                                        Pricing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#FAQ" className="FAQS">
                                                        FAQ
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Blog" className="menu  Blog">
                                                        Blog
                                                    </a>
                                                </li>
                                                <span className="register-home-btn ml-5">
                                                    <a
                                                        type="button"
                                                        className="btn btn-primary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                        href="/login"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Get started
                                                    </a>
                                                </span>
                                                {/* <li>
                                                    <Link to="/login" className="menu Blog">
                                                        Login
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            {/*-close header--*/}
                            <div className="container">
                                <div className="evie-slide">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div
                                                className="slider3-cnt slider4-cnt m-0"
                                                style={{ position: "relative", zIndex: 99 }}
                                            >
                                                <h2
                                                    className="hd fadesInUp"
                                                    style={{ animationDelay: "1.5s" }}
                                                >
                                                    Unleash the Potential of <br /> Document Managemant with
                                                    SnapIT
                                                </h2>
                                                <p className="fadesInUp" style={{ animationDelay: "2s" }}>
                                                    SnapIT enables you to streamline all your documents with{" "}
                                                    <br /> appropriate renewal reminders, additionally:
                                                </p>
                                                <ul className="p-0 m-0 list-unstyled">
                                                    <li
                                                        className="fadesInUp"
                                                        style={{ animationDelay: "3s" }}
                                                    >
                                                        <a href="#">
                                                            <img src={icon1} alt="" />
                                                            Add and manage <br /> employee documents
                                                        </a>{" "}
                                                    </li>
                                                    <li
                                                        className="fadesInUp"
                                                        style={{ animationDelay: "4s" }}
                                                    >
                                                        <a href="#">
                                                            <img src={icon2} alt="" />
                                                            Add Business Licenses
                                                        </a>{" "}
                                                    </li>
                                                    <li
                                                        className="fadesInUp"
                                                        style={{ animationDelay: "5s" }}
                                                    >
                                                        <a href="#">
                                                            <img src={icon1} alt="" />
                                                            Organize them under folder
                                                        </a>{" "}
                                                    </li>
                                                    <li
                                                        className="fadesInUp"
                                                        style={{ animationDelay: "6s" }}
                                                    >
                                                        <a href="#">
                                                            <img src={icon4} alt="" />
                                                            Provide access to <br /> Typing Centers, PROs
                                                        </a>{" "}
                                                    </li>
                                                    <li
                                                        className="fadesInUp"
                                                        style={{ animationDelay: "7s" }}
                                                    >
                                                        <a href="#">
                                                            <img src={icon5} alt="" />
                                                            Setup 3 levels of <br /> reminders for timely renewals
                                                        </a>{" "}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-md-4 moile1 viewport">
                                            <img src={mobile1} alt="" />
                                        </div>
                                    </div>
                                </div>
                                {/*-close evie-slide-*/}
                                <div className="leftside features">
                                    <h3 className="fadeInUp" style={{ animationDelay: "2s" }}>
                                        <a href="#">features</a>
                                    </h3>
                                </div>
                                <div className="copyright">
                                    <p>
                                        For First 100 Subscribers <small>|</small>Unlimited Documents,
                                        Folders, and Users.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={() => setInvite(e => !e)}
                                    >
                                        {" "}
                                        Contact us
                                    </button>
                                </div>
                                <div className="overly4"></div>
                            </div>
                        </div>
                        {/*-demohome-*/}
                    </SwiperSlide>
                    {/*-slider 4--*/}
                    <SwiperSlide className="swiper-slide">
                        <div className="overly2 demohome" id="Feature">
                            <header id="header">
                                <div className="container">
                                    <div className="header-dlf">
                                        <div className="header-logo">
                                            <Link to="/">
                                                <img src={iconSvg} alt="" />
                                                <svg
                                                    height={500}
                                                    width={1000}
                                                    stroke="#fff"
                                                    strokeWidth="0.8"
                                                    className="text-line"
                                                >
                                                    <text x={50} y={90} fill="none" className="snapit">
                                                        snap
                                                    </text>
                                                    <text x={200} y={90} fill="none" className="it">
                                                        it
                                                    </text>
                                                </svg>
                                            </Link>
                                        </div>
                                        <div className="header-menu">
                                            <ul className="list-unstyled m-0 p-0">
                                                <li>
                                                    <a href="#Works" className="Works">
                                                        How it Works
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Features" className="Features">
                                                        Feature
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Pricing" className="Pricing">
                                                        Pricing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#FAQ" className="FAQS">
                                                        FAQ
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Blog" className="menu  Blog">
                                                        Blog
                                                    </a>
                                                </li>
                                                <span className="register-home-btn ml-5">
                                                    <a
                                                        type="button"
                                                        className="btn btn-primary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                        href="/login"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Get started
                                                    </a>
                                                </span>
                                                {/* <li>
                                                    <Link to="/login" className="menu Blog">
                                                        Login
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            {/*-close header--*/}
                            <div className="container">
                                <div className="evie-slide">
                                    <h2 className="hd">You can count on us</h2>
                                    <div
                                        className="row"
                                        style={{
                                            alignItems: "center",
                                            position: "relative",
                                            zIndex: 99
                                        }}
                                    >
                                        <div
                                            className="col-md-4 box-reminder fadesInUp"
                                            style={{ animationDelay: "2s" }}
                                        >
                                            <div className="reminder-img">
                                                <img src={dataImg} alt="" />
                                            </div>
                                            <div className="textline">
                                                <h4>
                                                    <span>Secured Data</span>
                                                </h4>
                                                <h6>Data security is our top priority</h6>
                                                <p>
                                                    At SnapIT, data security is paramount. We implement
                                                    stringent measures, ensuring encrypted storage, access
                                                    controls, and regular audits, safeguarding your valuable
                                                    information with utmost reliability and confidentiality.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-4 box-reminder fadesInUp"
                                            style={{ animationDelay: "3s" }}
                                        >
                                            <div className="reminder-img interface">
                                                <img src={interfaceImg} alt="" />
                                            </div>
                                            <div className="textline">
                                                <h4>
                                                    <span>Flexible Interface</span>
                                                </h4>
                                                <h6>Attractive and friendly interface</h6>
                                                <p>
                                                    Attractive and friendly interface SnapIT offers an
                                                    intuitive interface designed for smooth navigation. Its
                                                    user-centric design ensures accessibility for all, making
                                                    document management a seamless experience for everyone,
                                                    zero training needed.
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-4 box-reminder fadesInUp"
                                            style={{ animationDelay: "4s" }}
                                        >
                                            <div className="reminder-img">
                                                <img src={reminder} alt="" />
                                            </div>
                                            <div className="textline">
                                                <h4>
                                                    <span>Get Reminded </span>
                                                </h4>
                                                <h6>Set 3 level default or customize reminders</h6>
                                                <p>
                                                    SnapIT's timely document renewal reminders saves you from
                                                    your unwanted penalties. Seamlessly integrated with
                                                    Document Renewal Companies.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*-close evie-slide-*/}
                                <div className="copyright">
                                    <p>
                                        For First 100 Subscribers <small>|</small>Unlimited Documents,
                                        Folders, and Users.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={() => setInvite(e => !e)}
                                    >
                                        {" "}
                                        Contact us
                                    </button>
                                </div>
                                <div className="leftside features">
                                    <h3 className="fadeInUp" style={{ animationDelay: "2s" }}>
                                        <a href="#">Features</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        {/*-demohome-*/}
                    </SwiperSlide>
                    {/*-slider 5--*/}
                    <SwiperSlide className="swiper-slide">
                        <div id="Pricing" className="overly2 demohome">
                            <header id="header">
                                <div className="container">
                                    <div className="header-dlf">
                                        <div className="header-logo">
                                            <Link to="/">
                                                <img src={iconSvg} alt="" />
                                                <svg
                                                    height={500}
                                                    width={1000}
                                                    stroke="#fff"
                                                    strokeWidth="0.8"
                                                    className="text-line"
                                                >
                                                    <text x={50} y={90} fill="none" className="snapit">
                                                        snap
                                                    </text>
                                                    <text x={200} y={90} fill="none" className="it">
                                                        it
                                                    </text>
                                                </svg>
                                            </Link>
                                        </div>
                                        <div className="header-menu">
                                            <ul className="list-unstyled m-0 p-0">
                                                <li>
                                                    <a href="#Works" className="Works">
                                                        How it Works
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Features" className="Features">
                                                        Feature
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Pricing" className="Pricing">
                                                        Pricing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#FAQ" className="FAQS">
                                                        FAQ
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Blog" className="menu  Blog">
                                                        Blog
                                                    </a>
                                                </li>
                                                <span className="register-home-btn ml-5">
                                                    <a
                                                        type="button"
                                                        className="btn btn-primary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                        href="/login"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Get started
                                                    </a>
                                                </span>
                                                {/* <li>
                                                    <Link to="/login" className="menu Blog">
                                                        Login
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            {/*-close header--*/}
                            <div className="container">
                                <div
                                    className="faq-section"
                                    style={{ position: "relative", zIndex: 99 }}
                                >
                                    <h2 className="hd">Give everyone a voice with the plan<br></br> that’s right for you</h2>
                                    <div className="row">
                                        <div className="col-md-3 fadesInUp " style={{ animationDelay: "3s" }}  >
                                            <PricingItem
                                                type={"Individual"}
                                                basicOffer={{ price: "FREE", value: "10 MB" }}
                                                proOffer={{ price: "FREE", value: "2 GB" }}
                                                premOffer={{ price: "$10", value: "10 GB" }}
                                            />

                                        </div>
                                        <div className="col-md-3 fadesInUp " style={{ animationDelay: "4s" }}  >
                                            <PricingItem
                                                type={"Business Owners"}
                                                basicOffer={{ price: "FREE", value: "50 MB" }}
                                                proOffer={{ price: "$6", value: "10 GB" }}
                                                premOffer={{ price: "$10", value: "35 GB" }}
                                            />
                                        </div>
                                        <div className="col-md-3 fadesInUp " style={{ animationDelay: "5s" }}  >
                                            <PricingItem
                                                type={"Typing Centers"}
                                                basicOffer={{ price: "FREE", value: "500 MB" }}
                                                proOffer={{ price: "$55", value: "25 GB" }}
                                                premOffer={{ price: "$10", value: "50 GB" }}
                                            />
                                        </div>
                                        <div className="col-md-3 fadesInUp " style={{ animationDelay: "6s" }}  >
                                            <PricingItem
                                                type={"P.R.O."}
                                                basicOffer={{ price: "FREE", value: "500 MB" }}
                                                proOffer={{ price: "$55", value: "25 GB" }}
                                                premOffer={{ price: "$10", value: "50 GB" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*-close evie-slide-*/}
                                <div className="viewport"></div>
                                <div className="copyright">
                                    <p>
                                        For First 100 Subscribers <small>|</small>Unlimited Documents,
                                        Folders, and Users.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={() => setInvite(e => !e)}
                                    >
                                        {" "}
                                        Contact us
                                    </button>
                                </div>
                                <div className="leftside faq">
                                    <h3 className="fadeInUp" style={{ animationDelay: "2s" }}>
                                        <a href="#">Pricing</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        {/*-demohome-*/}
                    </SwiperSlide>


                    <SwiperSlide className="swiper-slide">
                        <div id="FAQ" className="overly2 demohome">
                            <header id="header">
                                <div className="container">
                                    <div className="header-dlf">
                                        <div className="header-logo">
                                            <Link to="/">
                                                <img src={iconSvg} alt="" />
                                                <svg
                                                    height={500}
                                                    width={1000}
                                                    stroke="#fff"
                                                    strokeWidth="0.8"
                                                    className="text-line"
                                                >
                                                    <text x={50} y={90} fill="none" className="snapit">
                                                        snap
                                                    </text>
                                                    <text x={200} y={90} fill="none" className="it">
                                                        it
                                                    </text>
                                                </svg>
                                            </Link>
                                        </div>
                                        <div className="header-menu">
                                            <ul className="list-unstyled m-0 p-0">
                                                <li>
                                                    <a href="#Works" className="Works">
                                                        How it Works
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Features" className="Features">
                                                        Feature
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Pricing" className="Pricing">
                                                        Pricing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#FAQ" className="FAQS">
                                                        FAQ
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Blog" className="menu  Blog">
                                                        Blog
                                                    </a>
                                                </li>
                                                <span className="register-home-btn ml-5">
                                                    <a
                                                        type="button"
                                                        className="btn btn-primary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                        href="/login"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Get started
                                                    </a>
                                                </span>
                                                {/* <li>
                                                    <Link to="/login" className="menu Blog">
                                                        Login
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            {/*-close header--*/}
                            <div className="container">
                                <div
                                    className="faq-section"
                                    style={{ position: "relative", zIndex: 99 }}
                                >
                                    <h2 className="hd">Curious? Know More.</h2>
                                    <div className="row">
                                        <div
                                            className="col-md-6 fadesInUp"
                                            style={{ animationDelay: "2s" }}
                                        >
                                            <div className="faq-cnt">
                                                <div
                                                    className="accordion accordion-flush"
                                                    id="accordionFlushExample"
                                                >
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="flush-headingOne">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#flush-collapseOne"
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapseOne"
                                                            >
                                                                How does SnapIT help me?
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="flush-collapseOne"
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby="flush-headingOne"
                                                            data-bs-parent="#accordionFlushExample"
                                                        >
                                                            <div className="accordion-body">
                                                                <h6>SnapIT helps you with:</h6>
                                                                <p>
                                                                    Ease in organizing and managing your documents in
                                                                    one place.
                                                                    <br />
                                                                    Remind them to renew documents timely.
                                                                    <br />
                                                                    Anytime Anywhere, Access with Microsoft Cloud
                                                                    Security.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2
                                                            className="accordion-header"
                                                            id="flush-headingbuilts"
                                                        >
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#flush-collapsebuilts"
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapsebuilts"
                                                            >
                                                                I am a small Business Owner, Is SnapIT for me?
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="flush-collapsebuilts"
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby="flush-headingbuilts"
                                                            data-bs-parent="#accordionFlushExample"
                                                        >
                                                            <div className="accordion-body">
                                                                <p>
                                                                    Yes, SnapIT accepts all kinds of documents that
                                                                    need renewal like VISA, Business licenses,
                                                                    Insurance, Car papers, work permits, etc.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="flush-headingTwo">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#flush-collapseTwo"
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapseTwo"
                                                            >
                                                                Is SnapIT for PRO professionalse?
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="flush-collapseTwo"
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby="flush-headingTwo"
                                                            data-bs-parent="#accordionFlushExample"
                                                        >
                                                            <div className="accordion-body">
                                                                <p>
                                                                    Yes, The Public Record Officer (PRO) needs to
                                                                    maintain a lot of documents for all the employees
                                                                    and needs support for timely reminders. SnapIT
                                                                    does that.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2
                                                            className="accordion-header"
                                                            id="flush-headingThree"
                                                        >
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#flush-collapseThree"
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapseThree"
                                                            >
                                                                Is SnapIT free?
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="flush-collapseThree"
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby="flush-headingThree"
                                                            data-bs-parent="#accordionFlushExample"
                                                        >
                                                            <div className="accordion-body">
                                                                <p>
                                                                    Yes, SnapIT is free for 365 days from the time of
                                                                    Subscription.{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2
                                                            className="accordion-header"
                                                            id="flush-headingbuilt"
                                                        >
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#flush-collapsebuilt"
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapsebuilt"
                                                            >
                                                                What technology SnapIT is built on?
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="flush-collapsebuilt"
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby="flush-headingbuilt"
                                                            data-bs-parent="#accordionFlushExample"
                                                        >
                                                            <div className="accordion-body">
                                                                <p>
                                                                    SnapIT is built on secure technology using Python
                                                                    and Microsoft SQL and is available on the Cloud
                                                                    SaaS model.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="col-md-6 fadesInUp"
                                            style={{ animationDelay: "3s" }}
                                        >
                                            <div className="faq-cnt topfaq">
                                                <div
                                                    className="accordion accordion-flush"
                                                    id="accordionFlushExamples"
                                                >
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="flush-headingOnes">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#flush-collapseOnes"
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapseOnes"
                                                            >
                                                                Can I use SnapIT via my Mobile?
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="flush-collapseOnes"
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby="flush-headingOnes"
                                                            data-bs-parent="#accordionFlushExamples"
                                                        >
                                                            <div className="accordion-body">
                                                                <p>
                                                                    Under Beta Launch, only the Desktop version will
                                                                    be available. But as the work is in process on the
                                                                    Mobile Application, It will be available in the
                                                                    next version release.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="flush-headingTwos">
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#flush-collapseTwos"
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapseTwos"
                                                            >
                                                                Are my documents on SnapIT on SnapIT safe?
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="flush-collapseTwos"
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby="flush-headingTwos"
                                                            data-bs-parent="#accordionFlushExamples"
                                                        >
                                                            <div className="accordion-body">
                                                                <p>
                                                                    Yes, All documents are safe under Advanced
                                                                    encryption and Firewall. It is kept under
                                                                    Microsoft Assure cloud technology.{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2
                                                            className="accordion-header"
                                                            id="flush-headingThrees"
                                                        >
                                                            <button
                                                                className="accordion-button collapsed"
                                                                type="button"
                                                                data-bs-toggle="collapse"
                                                                data-bs-target="#flush-collapseThrees"
                                                                aria-expanded="false"
                                                                aria-controls="flush-collapseThrees"
                                                            >
                                                                How can I get Started with SnapIT?
                                                            </button>
                                                        </h2>
                                                        <div
                                                            id="flush-collapseThrees"
                                                            className="accordion-collapse collapse"
                                                            aria-labelledby="flush-headingThrees"
                                                            data-bs-parent="#accordionFlushExamples"
                                                        >
                                                            <div className="accordion-body">
                                                                <p>
                                                                    Currently, SnapIT is invitation-based, you can
                                                                    show your interest by filling up the form by Early
                                                                    Access. You will get an email to use the product
                                                                    as the Beta version is in launched early 2024.{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*-close evie-slide-*/}
                                <div className="viewport"></div>
                                <div className="copyright">
                                    <p>
                                        For First 100 Subscribers <small>|</small>Unlimited Documents,
                                        Folders, and Users.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={() => setInvite(e => !e)}
                                    >
                                        {" "}
                                        Contact us
                                    </button>
                                </div>
                                <div className="leftside faq">
                                    <h3 className="fadeInUp" style={{ animationDelay: "2s" }}>
                                        <a href="#">FAQ</a>
                                    </h3>
                                </div>
                                <div className="fullimg">
                                    <img
                                        src={qsnMark}
                                        alt=""
                                        className="fadesInUp"
                                        style={{ animationDelay: "5s" }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*-demohome-*/}
                    </SwiperSlide>
                    {/*-slider 6--*/}
                    <SwiperSlide className="swiper-slide">
                        <div id="Blog" className="overly2 demohome">
                            <header id="header">
                                <div className="container">
                                    <div className="header-dlf">
                                        <div className="header-logo">
                                            <Link to="/">
                                                <img src={iconSvg} alt="" />
                                                <svg
                                                    height={500}
                                                    width={1000}
                                                    stroke="#fff"
                                                    strokeWidth="0.8"
                                                    className="text-line"
                                                >
                                                    <text x={50} y={90} fill="none" className="snapit">
                                                        snap
                                                    </text>
                                                    <text x={200} y={90} fill="none" className="it">
                                                        it
                                                    </text>
                                                </svg>
                                            </Link>
                                        </div>
                                        <div className="header-menu">
                                            <ul className="list-unstyled m-0 p-0">
                                                <li>
                                                    <a href="#Works" className="Works">
                                                        How it Works
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Features" className="Features">
                                                        Feature
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Pricing" className="Pricing">
                                                        Pricing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#FAQ" className="FAQS">
                                                        FAQ
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Blog" className="menu  Blog">
                                                        Blog
                                                    </a>
                                                </li>
                                                <span className="register-home-btn ml-5">
                                                    <a
                                                        type="button"
                                                        className="btn btn-primary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                        href="/login"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Get started
                                                    </a>
                                                </span>
                                                {/* <li>
                                                    <Link to="/login" className="menu Blog">
                                                        Login
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            {/*-close header--*/}
                            <div className="container">
                                <div className="evie-slide">
                                    <h2 className="hd">
                                        For PROs, Typing Centers,
                                        <br />
                                        Business Owners, Individual Expats
                                    </h2>

                                    <Swiper {...blogSwipperSettings}>
                                        <div className="row fadesInUp" style={{ alignItems: "center", position: "relative", zIndex: 99 }} >
                                            <SwiperSlide>
                                                {/* removed class col-md-4 from box */}
                                                <div className=" box " style={{ animationDelay: "2s" }}  >
                                                    <div className="renewal-img blog-img">
                                                        <img src={blog4} alt="" />
                                                    </div>
                                                    <div className="textline blog-cnt">
                                                        {/* <h6>Posted on November 15,2023</h6> */}
                                                        <h4>
                                                            Why do businesses in the Gulf need an AI Document solution? (PART 1)
                                                        </h4>
                                                        <a
                                                            // data-bs-toggle="modal"
                                                            // data-bs-target="#exampleModal1"
                                                            className='read-more-btn'
                                                            onClick={() => setPop4(e => !e)}
                                                        >
                                                            Read more
                                                        </a>
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                            <SwiperSlide>
                                                {/* removed class col-md-4 from box */}
                                                <div className=" box " style={{ animationDelay: "2s" }}  >
                                                    <div className="renewal-img blog-img">
                                                        <img src={blog1} alt="" />
                                                    </div>
                                                    <div className="textline blog-cnt">
                                                        {/* <h6>Posted on November 15,2023</h6> */}
                                                        <h4>
                                                            Boost Business Efficiency: SnapIT's Impact on Document
                                                            management in the Gulf
                                                        </h4>
                                                        <a
                                                            // data-bs-toggle="modal"
                                                            // data-bs-target="#exampleModal1"
                                                            className='read-more-btn'
                                                            onClick={() => setPop1(e => !e)}
                                                        >
                                                            Read more
                                                        </a>
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                            <SwiperSlide>
                                                {/* removed class col-md-4 from box */}
                                                <div className=" box " style={{ animationDelay: "3s" }} >
                                                    <div className="renewal-img blog-img">
                                                        <img src={blog2} alt="" />
                                                    </div>
                                                    <div className="textline blog-cnt">
                                                        {/* <h6>Posted on November 22,2023</h6> */}
                                                        <h4>
                                                            SnapIT: Empowering Business Document Managers in the UAE
                                                            and Gulf Regions
                                                        </h4>
                                                        <a
                                                            // href="#"
                                                            // data-bs-toggle="modal"
                                                            // data-bs-target="#exampleModal2"
                                                            className='read-more-btn'
                                                            onClick={() => setPop2(e => !e)}
                                                        >
                                                            Read more
                                                        </a>
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                            <SwiperSlide>
                                                {/* removed class col-md-4 from box */}
                                                <div className=" box " style={{ animationDelay: "4s" }} >
                                                    <div className="renewal-img blog-img">
                                                        <img src={blog3} alt="" />
                                                    </div>
                                                    <div className="textline blog-cnt">
                                                        {/* <h6>Posted on November 28,2023</h6> */}
                                                        <h4>
                                                            SnapIT: Simplifying Document management for Expats in the
                                                            UAE and Gulf
                                                        </h4>
                                                        <a
                                                            // href="#"
                                                            // data-bs-toggle="modal"
                                                            // data-bs-target="#exampleModal3"
                                                            className='read-more-btn'
                                                            onClick={() => setPop3(e => !e)}
                                                        >
                                                            Read more
                                                        </a>
                                                    </div>
                                                </div>
                                            </SwiperSlide>

                                        </div>
                                    </Swiper>
                                </div>
                                {/*-close evie-slide-*/}
                                <div className="copyright">
                                    <p>
                                        For First 100 Subscribers <small>|</small>Unlimited Documents,
                                        Folders, and Users.
                                    </p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={() => setInvite(e => !e)}
                                    >
                                        {" "}
                                        Contact us
                                    </button>
                                </div>
                                <div className="leftside faq blog">
                                    <h3 className="fadeInUp" style={{ animationDelay: "2s" }}>
                                        <a href="#">Blog</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        {/*-demohome-*/}
                    </SwiperSlide>
                    {/*-slider 7--*/}
                    <SwiperSlide className="swiper-slide">
                        <div id="" className="overly2 demohome">
                            <header id="header">
                                <div className="container">
                                    <div className="header-dlf">
                                        <div className="header-logo">
                                            <Link to="/">
                                                <img src={iconSvg} alt="" />
                                                <svg
                                                    height={500}
                                                    width={1000}
                                                    stroke="#fff"
                                                    strokeWidth="0.8"
                                                    className="text-line"
                                                >
                                                    <text x={50} y={90} fill="none" className="snapit">
                                                        snap
                                                    </text>
                                                    <text x={200} y={90} fill="none" className="it">
                                                        it
                                                    </text>
                                                </svg>
                                            </Link>
                                        </div>
                                        <div className="header-menu">
                                            <ul className="list-unstyled m-0 p-0">
                                                <li>
                                                    <a href="#Works" className="Works">
                                                        How it Works
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Features" className="Features">
                                                        Feature
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Pricing" className="Pricing">
                                                        Pricing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#FAQ" className="FAQ">
                                                        FAQ
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Blog" className="menu  Blog">
                                                        Blog
                                                    </a>
                                                </li>
                                                <span className="register-home-btn ml-5">
                                                    <a
                                                        type="button"
                                                        className="btn btn-primary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                        href="/login"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Get started
                                                    </a>
                                                </span>
                                                {/* <li>
                                                    <Link to="/login" className="menu Blog">
                                                        Login
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            {/*-close header--*/}
                            <div className="container">
                                <div
                                    className="user-cnt fadesInUp"
                                    style={{ animationDelay: "2s" }}
                                >
                                    <h2 className="hd">For First 100 Subscribers</h2>
                                    <p>Unlimited Documents, Folders, and Users.</p>
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                        onClick={() => setInvite(e => !e)}
                                    >
                                        {" "}
                                        Contact us
                                    </button>
                                </div>
                                {/*-close evie-slide-*/}
                                <div className="viewport"></div>
                                <div className="leftside sub">
                                    <h3 className="fadeInUp" style={{ animationDelay: "2s" }}>
                                        <a href="#">Subscribers</a>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        {/*-demohome-*/}
                    </SwiperSlide>
                    {/*-slider 8--*/}
                </Swiper>
                <div className="swiper-pagination" />
                {/* If we need navigation buttons */}

                <div className="requestbtn relative">
                    <button className="btn">SCROLL</button>
                    <div className="swipper-nav-wrap absolute">
                        <div className="swiper-button-next" />
                        <div className="swiper-button-prev" />
                    </div>
                </div>
                {/* If we need scrollbar */}
                <div className="swiper-scrollbar" />
            </div>
            {/*-mobile responsive---*/}
            <div className="d-all overly2">
                <header id="mobilehd">
                    <div className="container">
                        <div className="header-dlf">
                            <div className="header-logo">
                                <Link to="/" className="mb1">
                                    <img src={iconSvg} alt="" />
                                    <svg
                                        height={500}
                                        width={1000}
                                        stroke="#fff"
                                        strokeWidth="0.8"
                                        className="text-line"
                                    >
                                        <text x={50} y={90} fill="none" className="snapit">
                                            snap
                                        </text>
                                        <text x={200} y={90} fill="none" className="it">
                                            it
                                        </text>
                                    </svg>
                                </Link>
                                <Link to="/" className="mb2">
                                    <img src={iconSvg} alt="" />
                                    <svg
                                        height={500}
                                        width={1000}
                                        stroke="#fff"
                                        strokeWidth="0.8"
                                        className="text-line"
                                    >
                                        <text x={50} y={90} fill="none" className="snapit">
                                            snap
                                        </text>
                                        <text x={156} y={90} fill="none" className="it">
                                            it
                                        </text>
                                    </svg>
                                </Link>
                            </div>
                            <div className="mobile-menu">
                                <nav className="navbar navbar-expand-lg navbar-dark ">
                                    <div className="togl">
                                        <span
                                            style={{ cursor: "pointer" }}
                                            className="click"
                                            onClick={openNav}
                                        >
                                            ☰{" "}
                                        </span>
                                    </div>
                                    <div id="myNav" className="overlay">
                                        <a
                                            href="javascript:void(0)"
                                            className="closebtn"
                                            onClick={closeNav}
                                        >
                                            <img src={nounCross} alt="" />
                                        </a>
                                        <div className="overlay-content">
                                            <ul className="list-unstyled m-0">
                                                <li>
                                                    <a href="#how" className="Works">
                                                        How it Works
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#fea" className="Features">
                                                        Feature
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Pricing" className="Pricing">
                                                        Pricing
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#faqs" className="FAQS">
                                                        FAQ
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#Blog" className="menu  Blog">
                                                        Blog
                                                    </a>
                                                </li>
                                                <span className="register-home-btn ml-5">
                                                    <a
                                                        type="button"
                                                        className="btn btn-primary"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                        href="/login"
                                                        style={{ fontWeight: "bold" }}
                                                    >
                                                        Get started
                                                    </a>
                                                </span>
                                                {/* <li>
                                                    <Link to="/login" className="menu Blog">
                                                        Login
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="banner1 middal">
                    <div className="container">
                        <div className="evie-slide">
                            <div className="row m-0">
                                <div className="col-lg-6 side-img">
                                    <div className="side-img2">
                                        <img src={mobileman} alt="" />
                                    </div>
                                    <div
                                        id="playButtons"
                                        className="lottie-icon fadeInUp"
                                        style={{ animationDelay: "1s" }}
                                    >
                                        {/* <lottie-player
                                            src="https://lottie.host/2335aa52-697b-4cc4-9240-71a16e69d53b/n2g8L5n0C0.json"
                                            background="transparent"
                                            speed={1}
                                            style={{ width: 200, height: 200 }}
                                            loop=""
                                            controls=""
                                            autoPlay=""
                                            direction={1}
                                            mode="normal"
                                        >
                                            {" "}
                                        </lottie-player> */}
                                        <Player
                                            autoplay
                                            loop
                                            src="https://lottie.host/2335aa52-697b-4cc4-9240-71a16e69d53b/n2g8L5n0C0.json"
                                            style={{ height: '200px', width: '200px' }}
                                        >
                                            {/* <Controls visible={true} buttons={['play', 'repeat', 'frame', 'debug']} /> */}
                                        </Player>
                                    </div>
                                    <audio id="myAudios" autoPlay="true">
                                        <source
                                            src="https://myblulex.com/demo2023/test/audio/iphone-message.mp3"
                                            type="audio/mp3"
                                        />
                                    </audio>
                                    <div
                                        className="popup-box  fadeIndownLast"
                                        style={{ animationDelay: "3s" }}
                                    >
                                        <h4>4 Custom Reminders</h4>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0">
                                    <div className="title">
                                        <h1>
                                            <span>Simplify</span> <span>License Renewals.</span>
                                            <span>Timely Renewal.</span> <span>Reminders.</span>
                                            <p>
                                                <span>Transform how you manage your </span>{" "}
                                                <span>documents with SnapIT.</span>
                                            </p>
                                            <span>
                                                <a
                                                    href="/login"
                                                    className="btn"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#exampleModal"
                                                    onClick={() => setInvite(e => !e)}
                                                >
                                                    Early Access
                                                </a>
                                            </span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*-close evie-slide-*/}
                        <div className="copyright bottom">
                            <p>
                                For First 100 Subscribers <br /> Unlimited Documents, Folders, and
                                Users.
                            </p>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => setInvite(e => !e)}
                            >
                                {" "}
                                Contact us
                            </button>
                        </div>
                    </div>
                </div>
                {/*-mobile 1-*/}
                <div className="banner1" id="how">
                    <div className="container">
                        <div className="banner2">
                            <h3 className="hd3">How it works</h3>
                            <h2 className="hd">Save your Documents and Forget it</h2>
                            <div
                                className="row"
                                style={{ alignItems: "center", position: "relative", zIndex: 99 }}
                            >
                                <div className="col-md-4" data-aos="fade-right">
                                    <div className="renewal-img">
                                        <img src={iconUpload} alt="" />
                                    </div>
                                    <div className="textline">
                                        <h4>
                                            <span>Easy</span> Documents <br /> Upload
                                        </h4>
                                        <p>
                                            Scan or Upload Documents through Any <br /> Device (Desktop,
                                            Tablets or Mobile)
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4" data-aos="fade-right">
                                    <div className="renewal-img">
                                        <img src={iconAI} alt="" />
                                    </div>
                                    <div className="textline">
                                        <h4>
                                            <span>Organize</span> with <br /> Artificial Intelligence{" "}
                                        </h4>
                                        <p>
                                            SnapIT securely organizes all your
                                            <br /> documents in one place. <br />
                                            Anytime Anywhere, Accessible
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4" data-aos="fade-right">
                                    <div className="renewal-img">
                                        <img src={iconReminder} alt="" />
                                    </div>
                                    <div className="textline">
                                        <h4>
                                            <span>Timely</span> <br /> Renewal Reminder
                                        </h4>
                                        <p>
                                            {" "}
                                            3 level Reminders on every renewal. <br />
                                            Let's you SAVE on penalties
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*-close evie-slide-*/}
                    </div>
                </div>

                {/*-demohome-*/}
                <div className="banner1 p-0" id="technolog">
                    <div className="container">
                        <div className="banner3">
                            <div className="row">
                                <div className="col-md-6">
                                    <div
                                        className="slider3-cnt"
                                        style={{ position: "relative", zIndex: 99 }}
                                    >
                                        <h3 className="hd3">Technologies</h3>
                                        <h2 className="hd">SnapIT is built with</h2>
                                        <ul className="p-0 m-0 list-unstyled" data-aos="fade-left">
                                            <li className="fadesInUp" style={{ animationDelay: "3s" }}>
                                                <a href="#">
                                                    <img src={SQLimg} alt="" />
                                                </a>
                                            </li>
                                            <li
                                                className="fadesInUp mb-0"
                                                style={{ animationDelay: "4s" }}
                                            >
                                                <a href="#">
                                                    <img src={github} alt="" />
                                                </a>{" "}
                                            </li>
                                            <li className="fadesInUp " style={{ animationDelay: "5s" }}>
                                                <a href="#">
                                                    <img src={phython} alt="" />
                                                </a>
                                            </li>
                                            <li
                                                className="fadesInUp mb-0"
                                                style={{ animationDelay: "6s" }}
                                            >
                                                <a href="#">
                                                    <img src={react} alt="" />
                                                </a>{" "}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    className="col-md-6 tech-img"
                                    data-aos="fade-right"
                                    data-aos-easing="linear"
                                    data-aos-duration={1500}
                                >
                                    <div className="tech-imgs">
                                        <img src={tech2} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*-close evie-slide-*/}
                    </div>
                </div>
                {/*-demohome-*/}
                <div className="banner1 p-0" id="fea">
                    <div className="container">
                        <div className="banner4">
                            <div className="row" style={{ position: "relative", zIndex: 99 }}>
                                <div className="col-md-8">
                                    <div
                                        className="slider3-cnt slider4-cnt m-0"
                                        style={{ position: "relative", zIndex: 99 }}
                                    >
                                        <h3 className="hd3">Features</h3>
                                        <h2 className="hd">
                                            Unleash the Potential of <br /> Document Managemant with
                                            SnapIT
                                        </h2>
                                        <p className="">
                                            SnapIT enables you to streamline all your documents with{" "}
                                            <br /> appropriate renewal reminders, additionally:
                                        </p>
                                        <ul className="p-0 m-0 list-unstyled" data-aos="fade-right">
                                            <li className="">
                                                <a href="#">
                                                    <img src={icon1} alt="" />
                                                    Add and manage <br /> employee documents
                                                </a>{" "}
                                            </li>
                                            <li className="">
                                                <a href="#">
                                                    <img src={icon2} alt="" />
                                                    Add Business Licenses
                                                </a>{" "}
                                            </li>
                                            <li className="">
                                                <a href="#">
                                                    <img src={icon1} alt="" />
                                                    Organize them under folder
                                                </a>{" "}
                                            </li>
                                            <li className="">
                                                <a href="#">
                                                    <img src={icon4} alt="" />
                                                    Provide access to <br /> Typing Centers, PROs
                                                </a>{" "}
                                            </li>
                                            <li className="">
                                                <a href="#">
                                                    <img src={icon5} alt="" />
                                                    Setup 3 levels of <br /> reminders for timely renewals
                                                </a>{" "}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-4 moile1 viewport">
                                    <img src={mobile1} alt="" />
                                </div>
                            </div>
                        </div>
                        {/*-close evie-slide-*/}
                    </div>
                </div>
                {/*-demohome-*/}
                <div className="banner1 p-0">
                    <div className="container">
                        <div className="banner4">
                            <h3 className="hd3">Features</h3>
                            <h2 className="hd">You can count on us</h2>
                            <div
                                className="row"
                                style={{ alignItems: "center", position: "relative", zIndex: 99 }}
                            >
                                <div className="col-md-4 box-reminder" data-aos="fade-down">
                                    <div className="reminder-img">
                                        <img src={dataImg} alt="" />
                                    </div>
                                    <div className="textline">
                                        <h4>
                                            <span>Secured Data</span>
                                        </h4>
                                        <h6>Data security is our top priority</h6>
                                        <p>
                                            At SnapIT, data security is paramount. We implement stringent
                                            measures, ensuring encrypted storage, access controls, and
                                            regular audits, safeguarding your valuable information with
                                            utmost reliability and confidentiality.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 box-reminder" data-aos="fade-down">
                                    <div className="reminder-img interface">
                                        <img src={interfaceImg} alt="" />
                                    </div>
                                    <div className="textline">
                                        <h4>
                                            <span>Flexible Interface</span>
                                        </h4>
                                        <h6>Attractive and friendly interface</h6>
                                        <p>
                                            Attractive and friendly interface SnapIT offers an intuitive
                                            interface designed for smooth navigation. Its user-centric
                                            design ensures accessibility for all, making document
                                            management a seamless experience for everyone, zero training
                                            needed.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 box-reminder" data-aos="fade-down">
                                    <div className="reminder-img">
                                        <img src={reminder} alt="" />
                                    </div>
                                    <div className="textline">
                                        <h4>
                                            <span>Get Reminded </span>
                                        </h4>
                                        <h6>Set 3 level default or customize reminders</h6>
                                        <p>
                                            SnapIT's timely document renewal reminders saves you from your
                                            unwanted penalties. Seamlessly integrated with Document
                                            Renewal Companies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*-close evie-slide-*/}
                    </div>
                </div>


                {/*-demohome-*/}
                <div className="banner1 p-0" id="Pricing">
                    <div className="container">
                        <div
                            className="faq-section"
                            style={{ position: "relative", zIndex: 99 }}
                        >
                            <h3 className="hd3">Pricing</h3>
                            <h2 className="hd">Give everyone a voice with the plan that’s right for you</h2>
                            <div className="row mt-5">
                                <div className="col-md-12 fadesInUp  " style={{ animationDelay: "3s" }}  >
                                    <PricingItem
                                        type={"Individual"}
                                        basicOffer={{ price: "FREE", value: "10 MB" }}
                                        proOffer={{ price: "FREE", value: "2 GB" }}
                                        premOffer={{ price: "$10", value: "10 GB" }}
                                    />

                                </div>
                                <div className="col-md-12 fadesInUp mt-4" style={{ animationDelay: "4s" }}  >
                                    <PricingItem
                                        type={"Business Owners"}
                                        basicOffer={{ price: "FREE", value: "50 MB" }}
                                        proOffer={{ price: "$6", value: "10 GB" }}
                                        premOffer={{ price: "$10", value: "35 GB" }}
                                    />
                                </div>
                                <div className="col-md-12 fadesInUp mt-4" style={{ animationDelay: "5s" }}  >
                                    <PricingItem
                                        type={"Typing Centers"}
                                        basicOffer={{ price: "FREE", value: "500 MB" }}
                                        proOffer={{ price: "$55", value: "25 GB" }}
                                        premOffer={{ price: "$10", value: "50 GB" }}
                                    />
                                </div>
                                <div className="col-md-12 fadesInUp mt-4" style={{ animationDelay: "6s" }}  >
                                    <PricingItem
                                        type={"P.R.O."}
                                        basicOffer={{ price: "FREE", value: "500 MB" }}
                                        proOffer={{ price: "$55", value: "25 GB" }}
                                        premOffer={{ price: "$10", value: "50 GB" }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*-close evie-slide-*/}
                    </div>
                </div>
                {/*-demohome-*/}




                {/*-demohome-*/}
                <div className="banner1 p-0" id="faqs">
                    <div className="container">
                        <div
                            className="faq-section"
                            style={{ position: "relative", zIndex: 99 }}
                        >
                            <h3 className="hd3">FAQ</h3>
                            <h2 className="hd">Curious? Know More.</h2>
                            <div className="row">
                                <div className="col-md-12 " data-aos="fade-down">
                                    <div className="faq-cnt">
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="does">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapsedoes"
                                                        aria-expanded="false"
                                                        aria-controls="collapsedoes"
                                                    >
                                                        How does SnapIT help me?
                                                    </button>
                                                    {/* <button className="accordion-button" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                 
                </button> */}
                                                </h2>
                                                <div
                                                    id="collapsedoes"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingdoes"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <h6>SnapIT helps you with:</h6>
                                                        <p>
                                                            Ease in organizing and managing your documents in one
                                                            place.
                                                            <br />
                                                            Remind them to renew documents timely.
                                                            <br />
                                                            Anytime Anywhere, Access with Microsoft Cloud
                                                            Security.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseTwo"
                                                        aria-expanded="false"
                                                        aria-controls="collapseTwo"
                                                    >
                                                        I am a small Business Owner, Is SnapIT for me?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseTwo"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingTwo"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Yes, SnapIT accepts all kinds of documents that need
                                                            renewal like VISA, Business licenses, Insurance, Car
                                                            papers, work permits, etc.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseThree"
                                                        aria-expanded="false"
                                                        aria-controls="collapseThree"
                                                    >
                                                        Is SnapIT for PRO professionalse?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseThree"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingThree"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Yes, The Public Record Officer (PRO) needs to maintain
                                                            a lot of documents for all the employees and needs
                                                            support for timely reminders. SnapIT does that.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFour">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapseFour"
                                                        aria-expanded="false"
                                                        aria-controls="collapseFour"
                                                    >
                                                        Can I use SnapIT via my Mobile?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapseFour"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingFour"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            This is the first item's accordion body. It is shown
                                                            by default the collapse plugin adds the appropriate
                                                            classes that we use to style each element.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="free">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapsefree"
                                                        aria-expanded="false"
                                                        aria-controls="collapsefree"
                                                    >
                                                        Is SnapIT free?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapsefree"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingfree"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Yes, SnapIT is free for 365 days from the time of
                                                            Subscription.{" "}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="built">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapsebuilt"
                                                        aria-expanded="false"
                                                        aria-controls="collapsebuilt"
                                                    >
                                                        What technology SnapIT is built on?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapsebuilt"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingbuilt"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            SnapIT is built on secure technology using Python and
                                                            Microsoft SQL and is available on the Cloud SaaS
                                                            model.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="via">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapsevia"
                                                        aria-expanded="false"
                                                        aria-controls="collapsevia"
                                                    >
                                                        How can I get Started with SnapIT?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapsevia"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingvia"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Currently, SnapIT is invitation-based, you can show
                                                            your interest by filling up the form by Early Access.
                                                            You will get an email to use the product as the Beta
                                                            version is in launched early 2024.{" "}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="safe">
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target="#collapsesafe"
                                                        aria-expanded="false"
                                                        aria-controls="collapsesafe"
                                                    >
                                                        Are my documents on SnapIT on SnapIT safe?
                                                    </button>
                                                </h2>
                                                <div
                                                    id="collapsesafe"
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="headingsafe"
                                                    data-bs-parent="#accordionExample"
                                                >
                                                    <div className="accordion-body">
                                                        <p>
                                                            Yes, All documents are safe under Advanced encryption
                                                            and Firewall. It is kept under Microsoft Assure cloud
                                                            technology.{" "}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*-close evie-slide-*/}
                        <div className="fullimg">
                            <img
                                src={qsnMark}
                                alt=""
                                className="fadesInUp"
                                style={{ animationDelay: "4s" }}
                            />
                        </div>
                    </div>
                </div>
                {/*-demohome-*/}
                <div className="banner1 p-0" id="blog">
                    <div className="container">
                        <div className="banner7">
                            <h3 className="hd3">Blog</h3>
                            <h2 className="hd">
                                For PROs, Typing Centers,
                                <br />
                                Business Owners, Individual Expats
                            </h2>
                            <div
                                className="row"
                                style={{ alignItems: "center", position: "relative", zIndex: 99 }}
                            >
                                <div className="col-md-4 blog3" data-aos="fade-up">
                                    <div className="renewal-img blog-img">
                                        <img src={blog4} alt="" />
                                    </div>
                                    <div className="textline blog-cnt">
                                        {/* <h6>Posted on November 15,2023</h6> */}
                                        <h4>SnapIT:</h4>
                                        <p>
                                            Why do businesses in the Gulf need
                                            <br />
                                            an AI Document solution? (PART 1)
                                        </p>
                                        <a
                                            // href="#"
                                            // data-bs-toggle="modal"
                                            // data-bs-target="#exampleModal1"
                                            className='read-more-btn'
                                            onClick={() => setPop1(e => !e)}
                                        >
                                            Read more
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 blog3" data-aos="fade-up">
                                    <div className="renewal-img blog-img">
                                        <img src={blog1} alt="" />
                                    </div>
                                    <div className="textline blog-cnt">
                                        {/* <h6>Posted on November 15,2023</h6> */}
                                        <h4>Boost Business Efficiency:</h4>
                                        <p>
                                            SnapIT's Impact on Document
                                            <br />
                                            management in the Gulf
                                        </p>
                                        <a
                                            // href="#"
                                            // data-bs-toggle="modal"
                                            // data-bs-target="#exampleModal1"
                                            className='read-more-btn'
                                            onClick={() => setPop1(e => !e)}
                                        >
                                            Read more
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 blog3" data-aos="fade-up">
                                    <div className="renewal-img blog-img">
                                        <img src={blog2} alt="" />
                                    </div>
                                    <div className="textline blog-cnt">
                                        {/* <h6>Posted on November 22,2023</h6> */}
                                        <h4>SnapIT:</h4>
                                        <p>
                                            Empowering Business Document <br /> Managers in the UAE and
                                            Gulf Regions
                                        </p>
                                        <a
                                            // href="#"
                                            // data-bs-toggle="modal"
                                            // data-bs-target="#exampleModal2"
                                            className='read-more-btn'
                                            onClick={() => setPop2(e => !e)}
                                        >
                                            Read more
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4 blog3" data-aos="fade-up">
                                    <div className="renewal-img blog-img">
                                        <img src={blog3} alt="" />
                                    </div>
                                    <div className="textline blog-cnt">
                                        {/* <h6>Posted on November 28,2023</h6> */}
                                        <h4>SnapIT:</h4>
                                        <p>
                                            Simplifying Document management <br /> for Expats in the UAE
                                            and Gulf
                                        </p>
                                        <a
                                            // href="#"
                                            // data-bs-toggle="modal"
                                            // data-bs-target="#exampleModal3"
                                            className='read-more-btn'
                                            onClick={() => setPop3(e => !e)}
                                        >
                                            Read more
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*-close evie-slide-*/}
                    </div>
                </div>
                {/*-demohome-*/}
                <div className="banner1" style={{ padding: "20px 0px 30px" }}>
                    <div className="container">
                        <div className="user-cnt fadesInUp" style={{ animationDelay: "2s" }}>
                            <h3 className="hd3">Subscribers</h3>
                            <h2 className="hd">For First 100 Subscribers</h2>
                            <p>Unlimited Documents, Folders, and Users.</p>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() => setInvite(e => !e)}
                            >
                                {" "}
                                Contact us
                            </button>
                        </div>
                        {/*-close evie-slide-*/}
                    </div>
                </div>
                {/*-demohome-*/}
            </div>
            {/*--popup--*/}
            <div className={`modal  popupbolg popup-wrapper ${pop4 && "active"}`}   >
                <div className="overly2" style={{ position: "relative" }}>
                    <header className="blogheader">
                        <div className="container">
                            <div className="header-dlf">
                                <div className="header-logo">
                                    <Link to="/" className="mb1">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={200} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                    <Link to="/" className="mb2">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={156} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                </div>
                                <div className="header-menu">
                                    <ul className="list-unstyled m-0 p-0">
                                        <li>
                                            <a href="#Works" className="Works">
                                                How it Works
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#Features" className="Features">
                                                Feature
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#Pricing" className="Pricing">
                                                Pricing
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#FAQ" className="FAQS">
                                                FAQ
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#Blog" className="menu  ">
                                                Blog
                                            </a>
                                        </li>
                                        <span className="register-home-btn ml-5">
                                            <a
                                                type="button"
                                                className="btn btn-primary"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                href="/login"
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Get started
                                            </a>
                                        </span>
                                        {/* <li>
                                            <Link to="/login" className="menu Blog">
                                                Login
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                    <header id="mobilehd" style={{ display: "none" }}>
                        <div className="container">
                            <div className="header-dlf">
                                <div className="header-logo">
                                    <Link to="/" className="mb1">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={200} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                    <Link to="/" className="mb2">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={156} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                </div>
                                <div className="mobile-menu">
                                    <nav className="navbar navbar-expand-lg navbar-dark ">
                                        <div className="togl">
                                            <span
                                                style={{ cursor: "pointer" }}
                                                className="click"
                                                onClick={openNavs}
                                            >
                                                ☰{" "}
                                            </span>
                                        </div>
                                        <div id="myNavs" className="overlay">
                                            <a
                                                href="javascript:void(0)"
                                                className="closebtn"
                                                onClick={closeNavs}
                                            >
                                                <img src={nounCross} alt="" />
                                            </a>
                                            <div className="overlay-content">
                                                <ul className="list-unstyled m-0">
                                                    <li>
                                                        <a href="#how" className="Works">
                                                            How it Works
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#fea" className="Features">
                                                            Feature
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#Pricing" className="Pricing">
                                                            Pricing
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#faqs" className="FAQS">
                                                            FAQ
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#Blog" className="menu  Blog">
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <span className="register-home-btn ml-5">
                                                        <a
                                                            type="button"
                                                            className="btn btn-primary"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal"
                                                            href="/login"
                                                            style={{ fontWeight: "bold" }}
                                                        >
                                                            Get started
                                                        </a>
                                                    </span>
                                                    {/* <li>
                                                        <Link to="/login" className="menu Blog">
                                                            Login
                                                        </Link>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="modal-dialog blog">
                        <div className="container">
                            <div className="modal-content model-content-wrapper">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => setPop4(e => !e)}
                                    />
                                </div>
                                <div className="modal-body">
                                    <div className="dtl-cnt">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <img src={blog4} alt="" />
                                            </div>
                                            <div className="col-md-8">
                                                <h2>
                                                    {/* Boost Business Efficiency: ffffffff */}
                                                    Why do businesses in <br /> the Gulf need an AI <br /> Document solution? (PART 1)
                                                    {/* <br />
                                                    SnapIT's Impact on Document
                                                    <br /> Management in the Gulf */}
                                                </h2>
                                            </div>
                                        </div>
                                        <p>
                                            The Gulf region's rapidly growing business environment is embracing AI-driven solutions like SnapIT for document management. These tools streamline operations, enhance security, and reduce manual workloads, transforming how companies handle paperwork and digital files.
                                            In this guide, we explore how AI is revolutionizing document management, improving efficiency, compliance, and productivity across industries.

                                        </p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ul>
                                                    <li>
                                                        Streamlining Document Processing with AI: <br />
                                                        Now, in the Gulf region, a revolutionary phenomenon in the management of documents can be marked due to SnapIT. This technology is so important in reducing the time spent in document processing which enhances the efficiency and accuracy.
                                                    </li>
                                                    <li>
                                                        Automated Data Extraction:
                                                        <br />
                                                        In document management, people are benefited from data extraction by using SnapIT “rightly”. We don’t consume the precious time of the employees typing in the data from the invoices, forms or contact.
                                                        <br />
                                                        By this advancement, artificial intelligence solutions can now extract information from different documents such as PDFs, images, and writing on paper. This automation does not only reduce the amount of time needed, but also reduces the probability of there being mistakes which could have resulted from manual entry of records.

                                                    </li>
                                                    <li>
                                                        Intelligent Document Classification:
                                                        <br />
                                                        Another application is document classification where implementation of AI is impressive. Thus, we’ve discovered that, in most cases, it takes AI a short amount of time to organize documents according to their content and structure.
                                                        <br />
                                                        The use of this capability can be pronounced in banking and credit organizations, insurance companies and government offices where large numbers of papers flow in and out daily.

                                                    </li>
                                                    <li>
                                                        Enhanced Search Capabilities:
                                                        <br />
                                                        Search technologies have been probably one of the most interesting areas where continued growth and development has been clearly demonstrated. Include search functions: Advanced searching of documents here has been made possible through use of SnapIT in document management.
                                                        <br />
                                                        Since these tools can reason in context and determine the user’s intentions they are perfect in helping us search for particular data in vast document archives. This improvement in search capability was highly significant to productivity, as we are now able to gain the data we require within seconds rather than hours.
                                                        <br />
                                                        Integrated artificial intelligence into the management of documents has brought a revolution in handling information in the region of the Gulf. Through improving their efficiency, minimizing errors, and enhancing data availability, different sectors contribute to breaking through the existing processes.

                                                    </li>
                                                    <li>
                                                        Enhancing Collaboration and Productivity:
                                                        <br />
                                                        We are seeing a revolution in team collaboration due to the use of SnapIT in the document management systems. Such intelligent solutions are revolutionizing social interaction and efficiency enhancing outcomes that were unthought of erstwhile.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <ul>

                                                    <li>
                                                        Real-time Document Collaboration:
                                                        <br />
                                                        One of the biggest advantages of implementing the technology is the ability to work on documents in real time. This feature has been a real game changer to teams especially those dealing with cross time zone challenges or remote ones.
                                                        <br />
                                                        The adoption of artificial intelligence results in collaborative document production, where several personnel within a given team are working on the same document, making changes, providing comments, and feedback in real-time.
                                                        <br />
                                                        This kind of collaboration avoids a situation where an individual is working on a document while the other party is editing a different copy of the same document, and saves time spent in mailing groups.

                                                    </li>
                                                    <li>
                                                        Automated Workflow Management:
                                                        <br />
                                                        Where AI is making a big difference at the moment is in helping to orchestrate the various processes in a company. The documents that we create and share also undergo development, editing, reviewing, approving and distribution all of which will be enabled by SnapIT.
                                                        <br />
                                                        These smart systems can then help to route the documents on the right individuals at the right time meaning that flow is enhanced. It is also able to advise on where there may likely be a bottleneck and how best to manage it or even correct it without being told.
                                                    </li>
                                                    <li>
                                                        Mobile Accessibility:
                                                        <br />
                                                        As the nature of businesses today demonstrates the ability to access and manipulate documents on the move has a lot of bearing on performance. Sophisticated mobile access capabilities are provided in our AI powered document management solution where we can view, modify and share documents situated on mobile devices and tablets.
                                                        <br />
                                                        These mobilities also make it possible for work not to be completed when the employees are not at the desks — our teams remain connected and productive all through.
                                                        <br />
                                                        Through the use of these approaches in AI, we are witnessing an incredible revolution in the way we coordinate our affairs and possibly our productivity in the Gulf. The advantages of enhanced coordination, integration and flexibility are aiding corporations and enterprises of all industries to remain effective and viable.

                                                    </li>
                                                    <li>
                                                        Conclusion:
                                                        <br />
                                                        SnapIT is revolutionizing document management in the Gulf, helping businesses work smarter and more securely. By automating processes and enhancing collaboration, these AI tools are crucial for staying competitive in the region’s dynamic market. As AI technology evolves, its impact on efficiency, security, and productivity will only grow.

                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="copyright ftrblog">
                                        <p>
                                            For First 100 Subscribers <small>|</small>Unlimited Documents,
                                            Folders, and Users.
                                        </p>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() => setInvite(e => !e)}
                                        >
                                            {" "}
                                            Contact us
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className={`modal  popupbolg popup-wrapper ${pop1 && "active"}`}   >
                <div className="overly2" style={{ position: "relative" }}>
                    <header className="blogheader">
                        <div className="container">
                            <div className="header-dlf">
                                <div className="header-logo">
                                    <Link to="/" className="mb1">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={200} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                    <Link to="/" className="mb2">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={156} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                </div>
                                <div className="header-menu">
                                    <ul className="list-unstyled m-0 p-0">
                                        <li>
                                            <a href="#Works" className="Works">
                                                How it Works
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#Features" className="Features">
                                                Feature
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#Pricing" className="Pricing">
                                                Pricing
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#FAQ" className="FAQS">
                                                FAQ
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#Blog" className="menu  ">
                                                Blog
                                            </a>
                                        </li>
                                        <span className="register-home-btn ml-5">
                                            <a
                                                type="button"
                                                className="btn btn-primary"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                href="/login"
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Get started
                                            </a>
                                        </span>
                                        {/* <li>
                                            <Link to="/login" className="menu Blog">
                                                Login
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                    <header id="mobilehd" style={{ display: "none" }}>
                        <div className="container">
                            <div className="header-dlf">
                                <div className="header-logo">
                                    <Link to="/" className="mb1">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={200} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                    <Link to="/" className="mb2">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={156} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                </div>
                                <div className="mobile-menu">
                                    <nav className="navbar navbar-expand-lg navbar-dark ">
                                        <div className="togl">
                                            <span
                                                style={{ cursor: "pointer" }}
                                                className="click"
                                                onClick={openNavs}
                                            >
                                                ☰{" "}
                                            </span>
                                        </div>
                                        <div id="myNavs" className="overlay">
                                            <a
                                                href="javascript:void(0)"
                                                className="closebtn"
                                                onClick={closeNavs}
                                            >
                                                <img src={nounCross} alt="" />
                                            </a>
                                            <div className="overlay-content">
                                                <ul className="list-unstyled m-0">
                                                    <li>
                                                        <a href="#how" className="Works">
                                                            How it Works
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#fea" className="Features">
                                                            Feature
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#Pricing" className="Pricing">
                                                            Pricing
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#faqs" className="FAQS">
                                                            FAQ
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#Blog" className="menu  Blog">
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <span className="register-home-btn ml-5">
                                                        <a
                                                            type="button"
                                                            className="btn btn-primary"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal"
                                                            href="/login"
                                                            style={{ fontWeight: "bold" }}
                                                        >
                                                            Get started
                                                        </a>
                                                    </span>
                                                    {/* <li>
                                                        <Link to="/login" className="menu Blog">
                                                            Login
                                                        </Link>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="modal-dialog blog">
                        <div className="container">
                            <div className="modal-content model-content-wrapper">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => setPop1(e => !e)}
                                    />
                                </div>
                                <div className="modal-body">
                                    <div className="dtl-cnt">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <img src={blog1} alt="" />
                                            </div>
                                            <div className="col-md-8">
                                                <h2>
                                                    Boost Business Efficiency:
                                                    <br />
                                                    SnapIT's Impact on Document
                                                    <br /> Management in the Gulf
                                                </h2>
                                            </div>
                                        </div>
                                        <p>
                                            In the dynamic business landscape of the UAE and Gulf regions,
                                            efficient document management is pivotal for sustained growth.
                                            SnapIT emerges as a game-changer, offering a comprehensive
                                            solution tailored for businesses aiming to streamline
                                            operations, enhance compliance, and mitigate penalties
                                            associated with expired documents.
                                        </p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ul>
                                                    <li>
                                                        Seamless Scanning and Storage: <br />
                                                        SnapIT simplifies document handling by allowing quick
                                                        and accurate scanning, enabling businesses to digitize
                                                        and securely store crucial documents. This streamlines
                                                        access, reduces physical storage needs, and ensures
                                                        information is readily available when needed.
                                                    </li>
                                                    <li>
                                                        Timely Renewal Reminders:
                                                        <br />
                                                        One of SnapIT's key features is its intelligent reminder
                                                        system, which notifies users well in advance of document
                                                        expiration dates. This proactive approach helps
                                                        businesses avoid penalties due to delayed renewals,
                                                        ensuring uninterrupted operations.
                                                    </li>
                                                    <li>
                                                        Enhanced Compliance and Efficiency:
                                                        <br />
                                                        In the UAE and Gulf countries, compliance with document
                                                        regulations is vital. SnapIT's organization and reminder
                                                        functionalities enable businesses to stay compliant
                                                        effortlessly. This proactive stance enhances operational
                                                        efficiency and credibility.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <ul>
                                                    <li>
                                                        Reduced Costs and Improved Productivity:
                                                        <br />
                                                        By digitizing documents and preventing penalties
                                                        resulting from expired documents, SnapIT helps in
                                                        reducing unnecessary expenses. Moreover, with
                                                        streamlined document access, businesses experience
                                                        improved productivity and streamlined workflows.
                                                    </li>
                                                    <li>
                                                        Adaptability and Accessibility:
                                                        <br />
                                                        SnapIT's user-friendly interface and adaptability to
                                                        various document types make it a valuable asset for
                                                        businesses of all sizes. Its cloud-based platform
                                                        ensures accessibility anytime, anywhere, catering to the
                                                        region's fast-paced business environment.
                                                    </li>
                                                    <li>
                                                        Data Security and Reliability:
                                                        <br />
                                                        With stringent security measures, SnapIT prioritizes
                                                        data protection. It ensures documents are stored
                                                        securely, employing encryption and access control,
                                                        instilling confidence in businesses about their document
                                                        integrity. SnapIT emerges as a catalyst for businesses
                                                        in the UAE and Gulf regions, revolutionizing document
                                                        management. Its functionalities offer a strategic edge,
                                                        enabling businesses to optimize operations, comply with
                                                        regulations, and safeguard against penalties, ultimately
                                                        fostering growth and sustainability in a competitive
                                                        market.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="copyright ftrblog">
                                        <p>
                                            For First 100 Subscribers <small>|</small>Unlimited Documents,
                                            Folders, and Users.
                                        </p>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() => setInvite(e => !e)}
                                        >
                                            {" "}
                                            Contact us
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className={`modal  popupbolg popup-wrapper ${pop2 && "active"}`}
            // id="exampleModal2"
            // tabIndex={-1}
            // aria-labelledby="exampleModalLabel"
            // aria-hidden="true"
            >
                <div className="overly2" style={{ position: "relative" }}>
                    <header className="blogheader">
                        <div className="container">
                            <div className="header-dlf">
                                <div className="header-logo">
                                    <Link to="/" className="mb1">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={200} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                    <Link to="/" className="mb2">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={156} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                </div>
                                <div className="header-menu">
                                    <ul className="list-unstyled m-0 p-0">
                                        <li>
                                            <a href="#Works" className="Works">
                                                How it Works
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#Features" className="Features">
                                                Feature
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#Pricing" className="Pricing">
                                                Pricing
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#FAQ" className="FAQS">
                                                FAQ
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#Blog" className="menu  Blog">
                                                Blog
                                            </a>
                                        </li>
                                        <span className="register-home-btn ml-5">
                                            <a
                                                type="button"
                                                className="btn btn-primary"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                href="/login"
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Get started
                                            </a>
                                        </span>
                                        {/* <li>
                                            <Link to="/login" className="menu Blog">
                                                Login
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                    <header id="mobilehd" style={{ display: "none" }}>
                        <div className="container">
                            <div className="header-dlf">
                                <div className="header-logo">
                                    <Link to="/" className="mb1">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={200} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                    <Link to="/" className="mb2">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={156} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                </div>
                                <div className="mobile-menu">
                                    <nav className="navbar navbar-expand-lg navbar-dark ">
                                        <div className="togl">
                                            <span
                                                style={{ cursor: "pointer" }}
                                                className="click"
                                                onClick={openNavs2}
                                            >
                                                ☰{" "}
                                            </span>
                                        </div>
                                        <div id="myNavs2" className="overlay">
                                            <a
                                                href="javascript:void(0)"
                                                className="closebtn"
                                                onClick={closeNavs2}
                                            >
                                                <img src={nounCross} alt="" />
                                            </a>
                                            <div className="overlay-content">
                                                <ul className="list-unstyled m-0">
                                                    <li>
                                                        <a href="#how" className="Works">
                                                            How it Works
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#fea" className="Features">
                                                            Feature
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#Pricing" className="Pricing">
                                                            Pricing
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#faqs" className="FAQS">
                                                            FAQ
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#Blog" className="menu  Blog">
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <span className="register-home-btn ml-5">
                                                        <a
                                                            type="button"
                                                            className="btn btn-primary"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal"
                                                            href="/login"
                                                            style={{ fontWeight: "bold" }}
                                                        >
                                                            Get started
                                                        </a>
                                                    </span>
                                                    {/* <li>
                                                        <Link to="/login" className="menu Blog">
                                                            Login
                                                        </Link>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="modal-dialog blog">
                        <div className="container">
                            <div className="modal-content model-content-wrapper">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"

                                        onClick={() => setPop2(e => !e)}
                                    />
                                </div>
                                <div className="modal-body">
                                    <div className="dtl-cnt">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <img src={blog2} alt="" />
                                            </div>
                                            <div className="col-md-8">
                                                <h2>
                                                    SnapIT: Empowering Business <br /> Document managers in
                                                    the
                                                    <br /> UAE and Gulf Regions
                                                </h2>
                                            </div>
                                        </div>
                                        <p>
                                            For Business Document Managers specializing in the creation
                                            and renewal of crucial documents in the UAE and Gulf
                                            countries, efficiency and precision are pivotal. Enter SnapIT,
                                            a comprehensive Document Management System designed to
                                            transform how these third-party organizations operate,
                                            delivering streamlined and reliable document management
                                            services.
                                        </p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ul>
                                                    <li>
                                                        Effortless Document Digitization: <br />
                                                        SnapIT simplifies document scanning and secure storage,
                                                        allowing swift digitization of essential paperwork. For
                                                        Document Managers handling vast volumes of documents,
                                                        this means streamlined processes and quick access to
                                                        critical information.
                                                    </li>
                                                    <li>
                                                        Proactive Renewal Reminders
                                                        <br />
                                                        The intelligent reminder system in SnapIT proves
                                                        invaluable for Document Managers. By issuing timely
                                                        alerts before document expiration dates, it empowers
                                                        them to stay ahead in renewals, avoiding penalties and
                                                        ensuring a seamless renewal experience for their
                                                        clients.
                                                    </li>
                                                    <li>
                                                        Enhanced Service Quality:
                                                        <br />
                                                        SnapIT's capabilities empower Document Managers to offer
                                                        superior services. They can assure clients of timely
                                                        document renewals, fostering trust and confidence in
                                                        their proficiency and reliability.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <ul>
                                                    <li>
                                                        Efficient Compliance Management:
                                                        <br />
                                                        Compliance is fundamental for these organizations.
                                                        SnapIT's functionalities aid in effortless compliance
                                                        management, reducing the risk of missed deadlines and
                                                        ensuring adherence to regulatory requirements.
                                                    </li>
                                                    <li>
                                                        Cost Savings and Productivity Gains:
                                                        <br />
                                                        SnapIT prevents penalties due to expired documents,
                                                        reducing operational costs for Document Managers. This
                                                        efficiency also translates into heightened productivity,
                                                        allowing them to focus on delivering top-notch services.
                                                    </li>
                                                    <li>
                                                        Data Security and Confidentiality:
                                                        <br />
                                                        With stringent security measures, SnapIT prioritizes
                                                        data confidentiality, crucial for managing sensitive
                                                        client information. Robust encryption and access
                                                        controls safeguard client documents, instilling trust in
                                                        Document Managers' capabilities. SnapIT emerges as a
                                                        strategic partner for Business Document Managers
                                                        specializing in document creation and renewal. Its
                                                        intuitive features optimize operations, ensure
                                                        compliance, and bolster service reliability, empowering
                                                        these organizations to deliver exceptional document
                                                        management services across the competitive landscape of
                                                        the UAE and Gulf regions
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="copyright ftrblog">
                                        <p>
                                            For First 100 Subscribers <small>|</small>Unlimited Documents,
                                            Folders, and Users.
                                        </p>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() => setInvite(e => !e)}
                                        >
                                            {" "}
                                            Contact us
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`modal  popupbolg popup-wrapper ${pop3 && "active"}`}
            // id="exampleModal3"
            // tabIndex={-1}
            // aria-labelledby=" exampleModalLabel"
            // aria-hidden="true"
            >
                <div className="overly2" style={{ position: "relative" }}>
                    <header className="blogheader">
                        <div className="container">
                            <div className="header-dlf">
                                <div className="header-logo">
                                    <Link to="/" className="mb1">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={200} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                    <Link to="/" className="mb2">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={156} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                </div>
                                <div className="header-menu">
                                    <ul className="list-unstyled m-0 p-0">
                                        <li>
                                            <a href="#Works" className="Works">
                                                How it Works
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#Features" className="Features">
                                                Feature
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#Pricing" className="Pricing">
                                                Pricing
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#FAQ" className="FAQS">
                                                FAQ
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#Blog" className="menu  Blog">
                                                Blog
                                            </a>
                                        </li>
                                        <span className="register-home-btn ml-5">
                                            <a
                                                type="button"
                                                className="btn btn-primary"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                                href="/login"
                                                style={{ fontWeight: "bold" }}
                                            >
                                                Get started
                                            </a>
                                        </span>
                                        {/* <li>
                                            <Link to="/login" className="menu Blog">
                                                Login
                                            </Link>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </header>
                    <header id="mobilehd" style={{ display: "none" }}>
                        <div className="container">
                            <div className="header-dlf">
                                <div className="header-logo">
                                    <Link to="/" className="mb1">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={200} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                    <Link to="/" className="mb2">
                                        <img src={iconSvg} alt="" />
                                        <svg
                                            height={500}
                                            width={1000}
                                            stroke="#fff"
                                            strokeWidth="0.8"
                                            className="text-line"
                                        >
                                            <text x={50} y={90} fill="none" className="snapit">
                                                snap
                                            </text>
                                            <text x={156} y={90} fill="none" className="it">
                                                it
                                            </text>
                                        </svg>
                                    </Link>
                                </div>
                                <div className="mobile-menu">
                                    <nav className="navbar navbar-expand-lg navbar-dark ">
                                        <div className="togl">
                                            <span
                                                style={{ cursor: "pointer" }}
                                                className="click"
                                                onClick={openNavs3}
                                            >
                                                ☰{" "}
                                            </span>
                                        </div>
                                        <div id="myNavs3" className="overlay">
                                            <a
                                                href="javascript:void(0)"
                                                className="closebtn"
                                                onClick={closeNavs3}
                                            >
                                                <img src={nounCross} alt="" />
                                            </a>
                                            <div className="overlay-content">
                                                <ul className="list-unstyled m-0">
                                                    <li>
                                                        <a href="#how" className="Works">
                                                            How it Works
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#fea" className="Features">
                                                            Feature
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#Pricing" className="Pricing">
                                                            Pricing
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#faqs" className="FAQS">
                                                            FAQ
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#Blog" className="menu  Blog">
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <span className="register-home-btn ml-5">
                                                        <a
                                                            type="button"
                                                            className="btn btn-primary"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal"
                                                            href="/login"
                                                            style={{ fontWeight: "bold" }}
                                                        >
                                                            Get started
                                                        </a>
                                                    </span>
                                                    {/* <li>
                                                        <Link to="/login" className="menu Blog">
                                                            Login
                                                        </Link>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="modal-dialog blog">
                        <div className="container">
                            <div className="modal-content model-content-wrapper">
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => setPop3(e => !e)}
                                    />
                                </div>
                                <div className="modal-body">
                                    <div className="dtl-cnt">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <img src={blog3} alt="" />
                                            </div>
                                            <div className="col-md-8">
                                                <h2>
                                                    SnapIT: Simplifying Document
                                                    <br /> management for expats in the
                                                    <br /> UAE and Gulf
                                                </h2>
                                            </div>
                                        </div>
                                        <p>
                                            For expatriates navigating the intricacies of document
                                            creation and renewal procedures in the UAE and Gulf countries,
                                            the process can be daunting. Enter SnapIT, a comprehensive
                                            Document Management System designed to alleviate the
                                            challenges faced by individual expats in managing crucial
                                            paperwork.
                                        </p>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <ul>
                                                    <li>
                                                        Effortless Document Digitization: <br />
                                                        SnapIT simplifies the digitization of important
                                                        documents, providing expats with an intuitive platform
                                                        to scan and securely store their paperwork. This feature
                                                        eases the transition to a digital environment, ensuring
                                                        easy access to essential information.
                                                    </li>
                                                    <li>
                                                        Timely Renewal Notifications:
                                                        <br />
                                                        One of SnapIT's standout features is its reminder
                                                        system, alerting expats well in advance of document
                                                        expiration dates. This proactive approach empowers
                                                        individuals to stay on top of renewals, avoiding
                                                        penalties and ensuring continued compliance.
                                                    </li>
                                                    <li>
                                                        Navigating Complex Procedures:
                                                        <br />
                                                        For expats unfamiliar with local documentation
                                                        processes, SnapIT serves as a valuable guide. Its
                                                        user-friendly interface and clear instructions
                                                        streamline the otherwise complex procedures, making the
                                                        document creation and renewal process more accessible.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <ul>
                                                    <li>
                                                        Ensuring Compliance and Reliability:
                                                        <br />
                                                        SnapIT's organization and reminder functionalities aid
                                                        expats in staying compliant with local regulations. This
                                                        helps in maintaining the legality and reliability of
                                                        their documents, ensuring hassle-free transitions in a
                                                        foreign country.
                                                    </li>
                                                    <li>
                                                        Cost-Efficiency and Time Savings:
                                                        <br />
                                                        By preventing penalties resulting from expired documents
                                                        and offering a streamlined process, SnapIT saves expats
                                                        valuable time and money. This efficiency allows them to
                                                        focus on settling in and enjoying their new
                                                        surroundings.
                                                    </li>
                                                    <li>
                                                        Data Security and Peace of Mind:
                                                        <br />
                                                        With robust security measures, SnapIT prioritizes the
                                                        security and confidentiality of expats' documents. This
                                                        ensures that sensitive information is safeguarded,
                                                        providing peace of mind amidst the relocation process.
                                                        SnapIT emerges as a lifeline for individual expatriates
                                                        grappling with document-related procedures in unfamiliar
                                                        territories. Its intuitive features, timely reminders,
                                                        and user-friendly interface empower expats to navigate
                                                        the complexities of document management in the UAE and
                                                        Gulf regions with ease, ensuring a smoother transition
                                                        and peace of mind during their stay abroad.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="copyright ftrblog">
                                        <p>
                                            For First 100 Subscribers <small>|</small>Unlimited Documents,
                                            Folders, and Users.
                                        </p>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            onClick={() => setInvite(e => !e)}
                                        >
                                            {" "}
                                            Contact us
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal */}
            <GetInvite invite={invite} setInvite={setInvite} inviteRef={inviteRef} />
            {/* <div  className={`modal  popup-wrapper invite-wrapper ${invite && "active"}`}   >
                <div className="modal-dialog">
                    <div className="modal-content model-content-wrapper invite-modal-wrap" ref={inviteRef}>
                        <div className="modal-header">
                            <h5 className="modal-title text-center">Contact us</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => setInvite(e => !e)}
                            />
                        </div>
                        <div className="modal-body">
                            <div className="form-invite">
                                <form>
                                    <div className="row">
                                        <div className="col-md-6 group-form">
                                            <input
                                                type=""
                                                name="name"
                                                className="form-control"
                                                placeholder=""
                                                required=""
                                            />
                                            <label>First Name</label>
                                        </div>
                                        <div className="col-md-6 group-form">
                                            <input
                                                type=""
                                                name="name"
                                                className="form-control"
                                                placeholder=""
                                                required=""
                                            />
                                            <label>Last Name</label>
                                        </div>
                                        <div className="col-md-6 group-form">
                                            <input
                                                type=""
                                                name="name"
                                                className="form-control"
                                                placeholder=""
                                                required=""
                                            />
                                            <label>Email</label>
                                        </div>
                                        <div className="col-md-6 group-form">
                                            <input
                                                type=""
                                                name="name"
                                                className="form-control"
                                                placeholder=""
                                                required=""
                                            />
                                            <label>Phone</label>
                                        </div>
                                        <div className="col-md-6 form-group select-wrapper">
                                            <div className="select-group "> 
                                                <select
                                                    className="form-select form-control addmember1"
                                                    id="selectCtrl"
                                                    aria-label="Default select example"
                                                >
                                                    <option>City</option>
                                                    <option value="">UAE</option>
                                                    <option value="">Saudi Arabia</option>
                                                    <option value="">Oman</option>
                                                    <option value="">Qatar</option>
                                                    <option value="">Kuwait</option>
                                                    <option value="">Bahrain</option>
                                                    <option value="">Jordan</option>
                                                    <option value="">Yemen</option>
                                                </select>
                                                <label>City</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6 group-form select-wrapper">
                                            <select
                                                className="form-select form-control addmember1"
                                                aria-label="Default select example"
                                            >
                                                <option>Type</option>
                                                <option value="">Business Owner</option>
                                                <option value="">Typing / Business Center</option>
                                                <option value="">Individual</option>
                                            </select>
                                            <label>Type</label>
                                        </div>
                                        <div className="col-md-12 text-center">
                                            <button type="submit" className="btn btn-primary">
                                                submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* Add script tags using Helmet */}
            {/* <Helmet>
                <script src="./js/jquery-3.4.1" type="text/javascript" />
                <script src="./js/bootstrap.bundle.min" type="text/javascript" />
                <script src="./js/swiper" type="text/javascript" />
                <script src="./js/lottie-player" type="text/javascript" />
                <script src="./js/aos" type="text/javascript" />
                <script src="./js/custom-js" type="text/javascript" />
            </Helmet> */}
        </div>
    )
}

export default LandingPage